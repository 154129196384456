import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Success({
  confirmationNumber,
}: {
  confirmationNumber: string;
}) {
  return (
    <Box p={4} py={8} display="flex" alignItems="center" flexDirection="column">
      <CheckCircleIcon sx={{ fontSize: "128px", mb: 2 }} color="success" />
      <Typography variant="h5">Booking succesfully submitted</Typography>
      <Typography variant="h6">
        Confirmation Number: {confirmationNumber}
      </Typography>
      <Typography>You will receive an email confirmation shortly.</Typography>
    </Box>
  );
}
