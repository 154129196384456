import { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import Card from "./Card";
import { SubmitBooking, BillingState, VerificationDetails } from "./types";

interface CreditCardProps {
  disableSubmit: boolean;
  submit: SubmitBooking;
  billingState: BillingState;
  pickupLocation: string;
  dropoffLocation: string;
  pickupDateString: string | null;
  price: number;
}
export default function CreditCard({
  disableSubmit,
  submit,
  billingState,
  pickupDateString,
  pickupLocation,
  dropoffLocation,
  price,
}: CreditCardProps) {
  const [buttonSubmitting, setButtonSubmitting] = useState(false);
  const [validCardNumber, setValidCardNumber] = useState(false);
  const [validExpirationDate, setValidExpirationDate] = useState(false);
  const [validCvv, setValidCvv] = useState(false);
  const [validPostalCode, setValidPostalCode] = useState(false);

  const creditCardValid =
    validCardNumber && validExpirationDate && validCvv && validPostalCode;

  const changeValid = useCallback((field: string, isValid: boolean) => {
    switch (field) {
      case "cardNumber":
        setValidCardNumber(isValid);
        break;
      case "expirationDate":
        setValidExpirationDate(isValid);
        break;
      case "cvv":
        setValidCvv(isValid);
        break;
      case "postalCode":
        setValidPostalCode(isValid);
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    Card.attach("card-container", "payment-status-container", changeValid);
    return () => {
      Card.remove();
    };
  }, []);

  const stringFormattedPrice = price.toFixed(2);
  const verificationDetails: VerificationDetails = useMemo(() => {
    return {
      amount: stringFormattedPrice,
      billingContact: {
        addressLines: [billingState.address1, billingState.address2].filter(
          (a) => a.length
        ),
        familyName: billingState.lastName,
        givenName: billingState.firstName,
        // email:
        country: "US",
        phone: billingState.phone,
        region: billingState.state,
        city: billingState.city,
      },
      currencyCode: "USD",
      intent: "CHARGE",
    };
  }, [
    billingState.address1,
    billingState.address2,
    billingState.city,
    billingState.firstName,
    billingState.lastName,
    billingState.phone,
    billingState.state,
    stringFormattedPrice,
  ]);

  return (
    <Box id="card-box">
      <div id="card-container"></div>
      <Box mb={2} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>Pickup Location: </Typography>
          <Typography fontWeight="bold">{pickupLocation}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>Pickup Time: </Typography>
          <Typography fontWeight="bold">{pickupDateString}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>Dropoff Location: </Typography>
          <Typography fontWeight="bold">{dropoffLocation}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>Price: </Typography>
          <Typography fontWeight="bold">${stringFormattedPrice} USD</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          disabled={buttonSubmitting || disableSubmit}
          id="card-button"
          variant="contained"
          loading={buttonSubmitting}
          onClick={async () => {
            if (buttonSubmitting) return;
            setButtonSubmitting(true);
            const tokens = await Card.getTokens(true, verificationDetails);
            const response = await submit(
              tokens?.token,
              tokens?.verificationToken,
              tokens?.error as string | undefined
            );
            if (response.status === "success") {
              Card.remove();
            } else {
              Card.clear();
            }
            setButtonSubmitting(false);
          }}
        >
          Pay & Submit
        </LoadingButton>
      </Box>
      <div id="payment-status-container"></div>
    </Box>
  );
}
