import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import HomePage from "./routes/home";
import BookPage from "./routes/book";
import AboutPage from "./routes/about";
import FleetPage from "./routes/fleet";
import ErrorPage from "./routes/error";

const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string | undefined;

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        { path: "", element: <HomePage /> },
        { path: "about", element: <AboutPage /> },
        { path: "book", element: <BookPage /> },
        { path: "fleet", element: <FleetPage /> },
      ],
    },
  ],
  { basename: baseUrl }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
