import { useCallback } from "react";
import { Box, TextField, Button, Typography, MenuItem } from "@mui/material";
import { InfoState } from "./types";

const PASSENGER_NUMBER_OPTIONS = [
  { value: 6, label: "6 Passenger SUV" },
  { value: 10, label: "10 Passenger Van" },
  { value: 14, label: "14 Passenger Sprinter" }
];

interface InformationProps {
  updateInfoState: <T extends keyof InfoState>(
    key: T,
    value: InfoState[T]
  ) => void;
  infoState: InfoState;
  errors: Record<string, string>;
  isMobile: boolean;
  setPriceError: (error?: string) => void;
  setPriceLoading: (loading: boolean) => void;
  setLocationFocused: (loading: boolean) => void;
}
export default function Information({
  updateInfoState,
  infoState,
  errors,
  isMobile,
  setPriceError,
  setPriceLoading,
  setLocationFocused,
}: InformationProps) {
  // 48 hours min
  const getPrice = useCallback(async () => {
    setLocationFocused(false);
    if (infoState.pickupLocation.length && infoState.dropoffLocation.length && infoState.passengerCount ) {
      setPriceLoading(true);
      try {
        const res = await fetch(
          `/prices?originAddress=${infoState.pickupLocation}&destinationAddress=${infoState.dropoffLocation}&passengerCount=${infoState.passengerCount}`
        );
        console.log(res, res.status, res.statusText);
        const result = await res.json();
        if (res.status === 200) {
          updateInfoState("price", result.price);
          setPriceError(undefined);
        } else {
          console.log(result);
          updateInfoState("price", null);
          setPriceError(("Error: " + result.message).toUpperCase())
        }
      } catch {
        updateInfoState("price", null);
        setPriceError("ERROR. Unable to get pricing. Please try again later.");
      } finally {
        setPriceLoading(false);
      }
    } else {
      updateInfoState("price", null);
    }
  }, [
    infoState.dropoffLocation,
    infoState.pickupLocation,
    infoState.passengerCount,
    setLocationFocused,
    setPriceError,
    setPriceLoading,
    updateInfoState,
  ]);

  return (
    <Box display="flex" flexDirection="column" gap={2} component="form">
      <Box height={100}/>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          onFocus={() => setLocationFocused(true)}
          onBlur={getPrice}
          id="pickup-location"
          value={infoState.pickupLocation}
          onChange={(e) => updateInfoState("pickupLocation", e.target.value)}
          sx={{
            width: "auto",
            maxWidth: "500px",
            minWidth: "300px"
          }}
          error={errors.price !== undefined}
          color={errors.price ? "error" : "primary"}
          helperText={errors.price ?? ""}
          label="From"
          placeholder="Address or Landmark"
          InputLabelProps={{style : {color : "white", fontSize : "large", fontFamily:"sans-serif", fontWeight:"bold"} }}
          variant="outlined"
          inputProps={{style : {color : "white", fontSize : "medium", backgroundColor:"#424242", borderRadius: "5px", fontFamily:"sans-serif"}, autoComplete:"off" }}
        />
        <TextField
          onFocus={() => setLocationFocused(true)}
          onBlur={getPrice}
          id="dropoff-location"
          value={infoState.dropoffLocation}
          onChange={(e) => updateInfoState("dropoffLocation", e.target.value)}
          sx={{
            width: "auto",
            maxWidth: "500px",
            minWidth: "300px"
          }}
          error={errors.price !== undefined}
          color={errors.price ? "error" : "primary"}
          label="To"
          placeholder="Address or Landmark"
          InputLabelProps={{style : {color : "white", fontSize : "large", fontFamily:"sans-serif", fontWeight:"bold"} }}
          variant="outlined"
          inputProps={{style : {color : "white", fontSize : "medium", backgroundColor:"#424242", borderRadius: "5px", fontFamily:"sans-serif"}, autoComplete:"off"}}

        />
        <TextField
          onFocus={() => setLocationFocused(true)}
          onBlur={getPrice}
          id="vehicle-type"
          select
          SelectProps={{
            style: { color: "white", backgroundColor: "#424242", borderRadius: "5px", fontFamily: "sans-serif" }
          }}
          label="Vehicle"
          placeholder=""
          value={infoState.passengerCount}
          InputLabelProps={{ style: { color: "white", fontSize: "large", fontFamily: "sans-serif", fontWeight: "bold" } }}
          inputProps={{ style: { color: "white", fontSize: "medium", backgroundColor: "#424242", borderRadius: "5px", fontFamily: "sans-serif" }, autoComplete: "off" }}
          onChange={(e) => updateInfoState("passengerCount", Number(e.target.value))}
          variant="outlined"
          sx={{
            width: "auto",
            maxWidth: "400px",
            minWidth: "150px" 
          }}
        >
          {PASSENGER_NUMBER_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value} style={{ color: "white", backgroundColor: "#424242" }}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box alignSelf="center" display="flex" flexDirection={isMobile ? "column" : "row"} gap={2} padding={2}>
        <Button variant="contained" style={{ backgroundColor:"#35baf6", fontSize:"medium", fontWeight:"bold"}}>
          Get Quote
        </Button>
      </Box>
      <Box height={20}/>
      <Box alignSelf="center" display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Typography fontSize={"large"} fontWeight={'bold'} color={"white"}>{ infoState.price != null
              ? `Total: $${infoState.price}`
              : ""}
        </Typography>
      </Box>
      <Box height={15}/>
    </Box>
  );
}
