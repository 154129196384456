import { Box, TextField, MenuItem } from "@mui/material";
import CreditCard from "./CreditCard";
import { BillingState, SubmitBooking } from "./types";
import { STATES } from "./constants";

interface BillingProps {
  updateBillingState: <T extends keyof BillingState>(
    key: T,
    value: BillingState[T]
  ) => void;
  billingState: BillingState;
  isValid: boolean;
  errors: Record<string, string>;
  submit: SubmitBooking;
  isMobile: boolean;
  pickupLocation: string;
  dropoffLocation: string;
  pickupDateString: string | null;
  price: number;
}

const STATE_OPTIONS = STATES.map((s) => ({ value: s.Code, label: s.Code }));

export default function Billing({
  updateBillingState,
  billingState,
  errors,
  isValid,
  submit,
  isMobile,
  pickupLocation,
  dropoffLocation,
  pickupDateString,
  price,
}: BillingProps) {
  return (
    <Box display="flex" flexDirection="column" gap={2} component="form">
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          id="first-name"
          value={billingState.firstName}
          onChange={(e) => {
            updateBillingState("firstName", e.target.value);
          }}
          sx={{ flex: 1 }}
          label="First Name"
          placeholder="Enter First Name"
        />
        <TextField
          value={billingState.lastName}
          onChange={(e) => {
            updateBillingState("lastName", e.target.value);
          }}
          id="last-name"
          sx={{ flex: 1 }}
          label="Last Name"
          placeholder="Enter Last Name"
        />
      </Box>
      <TextField
        fullWidth
        value={billingState.address1}
        onChange={(e) => {
          updateBillingState("address1", e.target.value);
        }}
        id="address-1"
        sx={{ flex: 1 }}
        label="Address 1"
        placeholder="Enter Address 1"
      />
      <TextField
        fullWidth
        value={billingState.address2}
        onChange={(e) => {
          updateBillingState("address2", e.target.value);
        }}
        id="address-2"
        sx={{ flex: 1 }}
        label="Address 2 (Optional)"
        placeholder="Enter Address 2 (Optional)"
      />
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          value={billingState.city}
          onChange={(e) => {
            updateBillingState("city", e.target.value);
          }}
          id="city"
          sx={{ flex: 1 }}
          label="City"
          placeholder="Enter City"
        />
        <TextField
          sx={{ flex: 1 }}
          value={billingState.state}
          onChange={(e) => {
            updateBillingState("state", e.target.value);
          }}
          id="state"
          select
          label="State"
          placeholder="State"
        >
          {STATE_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type="tel"
          id="phone"
          value={billingState.phone}
          onChange={(e) => {
            updateBillingState("phone", e.target.value);
          }}
          error={errors.phone !== undefined}
          color={errors.phone ? "error" : "primary"}
          sx={{ flex: 1 }}
          label="Phone Number"
          placeholder="(XXX)-XXX-XXXX"
        />
      </Box>

      <CreditCard
        disableSubmit={!isValid}
        submit={submit}
        billingState={billingState}
        pickupLocation={pickupLocation}
        dropoffLocation={dropoffLocation}
        pickupDateString={pickupDateString}
        price={price}
      />
    </Box>
  );
}
