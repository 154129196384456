import { useState, useMemo, useCallback } from "react";
import {
  Box,
  Alert,
  Collapse,
  Button,
  useMediaQuery,
  useTheme,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Information from "./Information";
import Billing from "./Billing";
import { BillingState, InfoState, SubmitBooking, AlertMessage } from "./types";
import { EMAIL_REGEX, PHONE_REGEX } from "./constants";
import Success from "./Success";
import moment from "moment";

const initialInfoState: InfoState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  pickupDateUTC: null,
  passengerCount: null,
  bagCount: null,
  pickupLocation: "",
  dropoffLocation: "",
  additionalInformation: "",
  price: null,
};

const initialBillingState: BillingState = {
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: null,
  phone: "",
  cardToken: null,
  verificationToken: null,
};

export default function BookPage() {
  const [activeCard, setActiveCard] = useState<"info" | "billing" | "success">(
    "info"
  );
  const [alert, setAlert] = useState<AlertMessage>();
  const [confirmationNumber, setConfirmationNumber] = useState<string>();
  const [infoState, setInfoState] = useState(initialInfoState);
  const [billingState, setBillingState] = useState(initialBillingState);
  const [priceError, setPriceError] = useState<string>();
  const [priceLoading, setPriceLoading] = useState(false);
  const [locationFocused, setLocationFocused] = useState(false);

  const updateInfoState = useCallback(
    <T extends keyof InfoState>(key: T, value: InfoState[T]) => {
      setInfoState({ ...infoState, [key]: value });
    },
    [infoState]
  );
  const updateBillingState = useCallback(
    <T extends keyof BillingState>(key: T, value: BillingState[T]) => {
      setBillingState({ ...billingState, [key]: value });
    },
    [billingState]
  );
  const informationErrors = useMemo(() => {
    const email =
      !!infoState.email.length && !EMAIL_REGEX.test(infoState.email)
        ? "Improper email address format."
        : null;
    const phone =
      !!infoState.phone.length && !PHONE_REGEX.test(infoState.phone)
        ? "Improper phone format."
        : null;
    const errors = {} as Record<string, string>;
    if (phone) errors.phone = phone;
    if (email) errors.email = email;
    if (priceError) errors.price = priceError;
    return errors;
  }, [infoState.email, infoState.phone, priceError]);

  const billingErrors = useMemo(() => {
    const phone =
      !!billingState.phone.length && !PHONE_REGEX.test(billingState.phone)
        ? "Improper phone format."
        : null;
    const errors = {} as Record<string, string>;
    if (phone) errors.phone = phone;
    return errors;
  }, [billingState.phone]);

  const isInformationValid = useMemo(() => {
    return !!(
      infoState.firstName.length &&
      infoState.lastName.length &&
      infoState.email.length &&
      infoState.phone.length &&
      infoState.pickupDateUTC?.length &&
      infoState.passengerCount != null &&
      infoState.bagCount != null &&
      infoState.pickupLocation.length &&
      infoState.dropoffLocation.length &&
      infoState.price != null &&
      !priceLoading &&
      !locationFocused &&
      Object.keys(informationErrors).length === 0
    );
  }, [infoState, informationErrors, priceLoading, locationFocused]);

  const isBillingValid = useMemo(() => {
    return !!(
      billingState.firstName.length &&
      billingState.lastName.length &&
      billingState.address1.length &&
      billingState.city.length &&
      billingState.state != null &&
      billingState.phone.length &&
      Object.keys(billingErrors).length === 0
    );
  }, [billingState, billingErrors]);

  const submitBooking: SubmitBooking = useCallback(
    async (token?: string, verificationToken?: string, error?: string) => {
      if (error != null || !token) {
        setAlert({
          severity: "error",
          message: error ?? "An error has occurred. Please try again.",
        });
        return {
          status: "error",
          message: error ?? "An error has occurred. Please try again.",
        };
      }
      const payload = {
        ...infoState,
        token,
        verificationToken,
      };
      try {
        const res = await fetch("/transfers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        const response = await res.json();
        if (res.status === 200) {
          setAlert({
            severity: "success",
            message: response?.message ?? "Booking Received.",
          });
          setActiveCard("success");
          setConfirmationNumber(response?.confirmationNumber as string);
          setInfoState(initialInfoState);
          setBillingState(initialBillingState);
          return {
            status: "success",
            message: response?.message ?? "Booking Received.",
          };
        } else {
          setAlert({
            severity: "error",
            message: response?.message ?? "Error Booking Reservation.",
          });
          return {
            status: "error",
            message: response?.message ?? "Error Booking Reservation.",
          };
        }
      } catch {
        setAlert({
          severity: "error",
          message: "Error Booking Reservation.",
        });
        return {
          status: "error",
          message: "Error Booking Reservation.",
        };
      }
    },
    [infoState]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ maxWidth: "800px", margin: "auto" }}>
        <Collapse in={alert !== undefined}>
          <Alert
            severity={alert?.severity}
            onClose={() => {
              setAlert(undefined);
            }}
            sx={{ mb: 2 }}
          >
            {alert?.message}
          </Alert>
        </Collapse>
        <Card sx={{ my: 4 }}>
          <CardHeader
            title="INFORMATION"
            action={
              activeCard === "billing" ? (
                <Button onClick={() => setActiveCard("info")}>VIEW</Button>
              ) : undefined
            }
          />
          {activeCard == "info" && (
            <CardContent>
              <Information
                isMobile={isMobile}
                updateInfoState={updateInfoState}
                setPriceError={setPriceError}
                infoState={infoState}
                errors={informationErrors}
                setPriceLoading={setPriceLoading}
                setLocationFocused={setLocationFocused}
              />
              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                  onClick={() => setActiveCard("billing")}
                  disabled={!isInformationValid}
                >
                  Next
                </Button>
              </Box>
            </CardContent>
          )}
        </Card>
        <Card sx={{ my: 4 }}>
          <CardHeader title="BILLING" />
          {activeCard == "billing" && (
            <CardContent>
              <Billing
                price={infoState.price as number}
                pickupLocation={infoState.pickupLocation}
                dropoffLocation={infoState.dropoffLocation}
                pickupDateString={
                  infoState.pickupDateUTC
                    ? moment(infoState.pickupDateUTC).format(
                        "MMMM Do YYYY, h:mm a"
                      )
                    : null
                }
                isMobile={isMobile}
                submit={submitBooking}
                isValid={isBillingValid && isInformationValid}
                errors={billingErrors}
                updateBillingState={updateBillingState}
                billingState={billingState}
              />
            </CardContent>
          )}
        </Card>
        {activeCard === "success" && (
          <Card sx={{ my: 4 }}>
            <Success confirmationNumber={confirmationNumber as string} />
          </Card>
        )}
      </Box>
    </LocalizationProvider>
  );
}
