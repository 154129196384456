import { Box, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function AboutPage() {
  const theme = useTheme();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h4" fontWeight={"bold"} color="common.white" marginBottom={5}>About Us</Typography>
        &nbsp;
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5 }}
      >
        <Typography variant="h6" color="common.white">Welcome to ESL, your premier choice for group transportation across the vibrant locales of South Florida, including Miami and Ft. Lauderdale. At ESL, we are not just about getting you from point A to B; we&apos;re about creating an experience that is as delightful and efficient as the destinations you&apos;re visiting.</Typography>
        &nbsp;
        <Typography variant="h6" color="common.white">Our services extend beyond just Miami and Ft. Lauderdale, encompassing an array of destinations including hotels, residences, and the numerous entertainment venues sprinkled throughout South Florida. Whether you&apos;re planning a corporate event, a family reunion, or simply a fun outing with friends, our fleet is ready to accommodate your group with comfort and style.</Typography>
        &nbsp;
        <Typography variant="h6" color="common.white">With a steadfast commitment to forging lasting relationships with our clients, our mission transcends beyond providing transportation - we aim to be your reliable partner in all your group travel needs. As a family-owned enterprise, we embody the essence of Southern hospitality, blending quality service with a personal touch.</Typography>
        &nbsp;
        <Typography variant="h6" color="common.white">Our founders have redefined group travel in the region, extending our services to include not just South Florida but also enchanting destinations like Orlando and Key West. Trust, professionalism, and an unwavering dedication to our customers are the cornerstones of our business.</Typography>
        &nbsp;
        <Typography variant="h6" color="common.white">Choose ESL for your group transportation needs and experience the true spirit of South Florida with every journey!</Typography>
        &nbsp;
      </Box>
    </Box>
  );
}