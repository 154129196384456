import { Box, Typography, useTheme, Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useState, useMemo, useCallback } from "react";
import Information from "./Information";
import { InfoState} from "./types";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const initialInfoState: InfoState = {
  pickupLocation: "",
  dropoffLocation: "",
  passengerCount: null,
  price: null,
};
const handlePhoneLinkClick = () => {
  window.location.href = `tel:+1305-965-4742`;
};
const handleEmailLinkClick = () => {
  window.location.href = `mailto:reservations@expressshuttleandlimo`;
};
export default function HomePage() {
  const [infoState, setInfoState] = useState(initialInfoState);
  const [priceError, setPriceError] = useState<string>();
  const [priceLoading, setPriceLoading] = useState(false);
  const [locationFocused, setLocationFocused] = useState(false);

  const updateInfoState = useCallback(
    <T extends keyof InfoState>(key: T, value: InfoState[T]) => {
      setInfoState({ ...infoState, [key]: value });
    },
    [infoState]
  );
  const informationErrors = useMemo(() => {
    const errors = {} as Record<string, string>;
    if (priceError) errors.price = priceError;
    return errors;
  }, [priceError]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5, display: 'none'}}
        overflow="auto"
      >
        <Typography variant="h6" color="common.white">Miami Airport, Port of Miami, Fort Lauderdale Airport, Miami Hotel transportation, South Beach transportation, Miami cruise transportation </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        minHeight="50vh"
        overflow="auto"
      >
        <Information
          isMobile={isMobile}
          updateInfoState={updateInfoState}
          setPriceError={setPriceError}
          infoState={infoState}
          errors={informationErrors}
          setPriceLoading={setPriceLoading}
          setLocationFocused={setLocationFocused}
        />
        <Box display="flex" justifyContent="center" padding={3}>
          <Button variant="contained" style={{ backgroundColor: '#35baf6' }} onClick={handlePhoneLinkClick}>
            <Typography>Call Now <LocalPhoneIcon fontSize="small" style={{ position: "relative", top: "3px" }}></LocalPhoneIcon></Typography>
          </Button>
          <Box width={50} />
          <Button variant="contained" style={{ backgroundColor: '#35baf6' }} onClick={handleEmailLinkClick}>
            <Typography>Email Us <EmailIcon fontSize="small" style={{ position: "relative", top: "3px" }}></EmailIcon></Typography>
          </Button>
          <Box width={50} />
          <Link to="/book">
            {infoState.pickupLocation && infoState.dropoffLocation && infoState.passengerCount && (
            <Button variant="contained" size="large" style={{ backgroundColor:"#35baf6"}}>
              <Typography
                p={1}
                sx={{
                  borderRadius: 1,
                  backgroundColor: "#35baf6",
                }}
                fontFamily={"sans-serif"}
                fontWeight={"Bold"}
              >
                Book Now!
              </Typography>
            </Button>
            )}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
