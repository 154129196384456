import { Link } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";

export default function ErrorPage() {
  return (
    <Box
      id="error-page"
      sx={{ width: "100%", height: "100%" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="h4">Oops!</Typography>
      <Typography>Sorry, an unexpected error has occurred.</Typography>
      <Link to="/">
        <Button>Return Home</Button>
      </Link>
    </Box>
  );
}
