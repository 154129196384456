import { useMemo, useCallback } from "react";
import { Box, TextField, MenuItem, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { InfoState } from "./types";
import moment, { Moment } from "moment";

/*const PASSENGER_NUMBER_OPTIONS = new Array(14)
  .fill(0)
  .map((_, i) => ({ value: i + 1, label: i + 1 }));
*/
const PASSENGER_NUMBER_OPTIONS = [
  { value: 6, label: "6 Passenger SUV" },
  { value: 10, label: "10 Passenger Van" },
  { value: 14, label: "14 Passenger Sprinter" }
];
const BAG_NUMBER_OPTIONS = new Array(19)
  .fill(0)
  .map((_, i) => ({ value: i, label: i }));

interface InformationProps {
  updateInfoState: <T extends keyof InfoState>(
    key: T,
    value: InfoState[T]
  ) => void;
  infoState: InfoState;
  errors: Record<string, string>;
  isMobile: boolean;
  setPriceError: (error?: string) => void;
  setPriceLoading: (loading: boolean) => void;
  setLocationFocused: (loading: boolean) => void;
}
export default function Information({
  updateInfoState,
  infoState,
  errors,
  isMobile,
  setPriceError,
  setPriceLoading,
  setLocationFocused,
}: InformationProps) {
  // 48 hours min
  const minDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 2);
    return moment(date);
  }, []);
  const getPrice = useCallback(async () => {
    setLocationFocused(false);
    if (infoState.pickupLocation.length && infoState.dropoffLocation.length) {
      setPriceLoading(true);
      try {
        const res = await fetch(
          `/prices?originAddress=${infoState.pickupLocation}&destinationAddress=${infoState.dropoffLocation}&passengerCount=${infoState.passengerCount}`
        );
        console.log(res, res.status, res.statusText);
        const result = await res.json();
        if (res.status === 200) {
          updateInfoState("price", result.price);
          setPriceError(undefined);
        } else {
          console.log(result);
          updateInfoState("price", null);
          setPriceError(("Error: " + result.message).toUpperCase())
        }
      } catch {
        updateInfoState("price", null);
        setPriceError("ERROR. Unable to get pricing. Please try again later.");
      } finally {
        setPriceLoading(false);
      }
    } else {
      updateInfoState("price", null);
    }
  }, [
    infoState.dropoffLocation,
    infoState.pickupLocation,
    setLocationFocused,
    setPriceError,
    setPriceLoading,
    updateInfoState,
  ]);

  return (
    <Box display="flex" flexDirection="column" gap={2} component="form">
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          id="first-name"
          value={infoState.firstName}
          onChange={(e) => updateInfoState("firstName", e.target.value)}
          sx={{ flex: 1 }}
          label="First Name"
          placeholder="Enter First Name"
        />
        <TextField
          id="last-name"
          value={infoState.lastName}
          onChange={(e) => updateInfoState("lastName", e.target.value)}
          sx={{ flex: 1 }}
          label="Last Name"
          placeholder="Enter Last Name"
        />
      </Box>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          id="email"
          value={infoState.email}
          onChange={(e) => updateInfoState("email", e.target.value)}
          sx={{ flex: 1 }}
          label="Email"
          error={errors.email !== undefined}
          placeholder="Enter Email"
          color={errors.email ? "error" : "primary"}
        />
        <TextField
          id="phone"
          type="tel"
          value={infoState.phone}
          onChange={(e) => {
            updateInfoState("phone", e.target.value);
          }}
          error={errors.phone !== undefined}
          color={errors.phone ? "error" : "primary"}
          sx={{ flex: 1 }}
          label="Phone"
          placeholder="(XXX)-XXX-XXXX"
        />
      </Box>
      <DateTimePicker
        label="Pickup Date"
        value={infoState.pickupDateUTC}
        onChange={(date: Moment | null) => {
          updateInfoState(
            "pickupDateUTC",
            date === null ? null : date.toISOString()
          );
        }}
        minDate={minDate}
        renderInput={(params) => (
          <TextField
            fullWidth
            id="pickup-date"
            placeholder="Pickup Date"
            {...params}
          />
        )}
      />
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          onFocus={() => setLocationFocused(true)}
          onBlur={getPrice}
          sx={{ flex: 1 }}
          id="vehicle-type"
          select
          label="Vehicle"
          placeholder=""
          value={infoState.passengerCount}
          onChange={(e) => {
            updateInfoState("passengerCount", Number(e.target.value));
          }}
        >
          {PASSENGER_NUMBER_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ flex: 1 }}
          id="number-bags"
          select
          label="Number of Bags"
          placeholder="Number of Bag"
          value={infoState.bagCount}
          onChange={(e) => {
            updateInfoState("bagCount", Number(e.target.value));
          }}
        >
          {BAG_NUMBER_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <TextField
          onFocus={() => setLocationFocused(true)}
          onBlur={getPrice}
          id="pickup-location"
          value={infoState.pickupLocation}
          onChange={(e) => updateInfoState("pickupLocation", e.target.value)}
          sx={{ flex: 1 }}
          error={errors.price !== undefined}
          color={errors.price ? "error" : "primary"}
          helperText={errors.price ?? ""}
          label="Pickup Location"
          placeholder="Pickup Location"
        />
        <TextField
          onFocus={() => setLocationFocused(true)}
          onBlur={getPrice}
          id="dropoff-location"
          value={infoState.dropoffLocation}
          onChange={(e) => updateInfoState("dropoffLocation", e.target.value)}
          sx={{ flex: 1}}
          error={errors.price !== undefined}
          color={errors.price ? "error" : "primary"}
          label="Dropoff Location"
          placeholder="Dropoff Location"
        />
      </Box>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <Typography fontWeight={'bold'}>{ infoState.price != null
              ? `Total Price: $${infoState.price} USD`
              : ""}</Typography>
      </Box>
      <TextField
        fullWidth
        id="additional-information"
        label="Additional Information"
        placeholder="Enter any Additional Information (e.g. Flight #, Cruise Name, or any other special requests)"
        multiline
        rows={4}
        value={infoState.additionalInformation}
        onChange={(e) => {
          updateInfoState("additionalInformation", e.target.value);
        }}
      />
    </Box>
  );
}
