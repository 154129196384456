import { Box, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Fleet from "../../Images/mia-fleet.jpg";
import Fleet2 from "../../Images/VanPics/carseats.jpg";
import Fleet3 from "../../Images/VanPics/esl-teampic-1.jpg";
import Fleet4 from "../../Images/VanPics/interior-1.jpg";
import Fleet5 from "../../Images/VanPics/interior-2.jpg";

export default function FleetPage() {
  const theme = useTheme();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h4" fontWeight={"bold"} color="common.white" marginBottom={5}>Our Fleet</Typography>
        &nbsp;
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5 }}
      >
        <Typography variant="h6" color="common.white">At ESL, our diverse fleet is meticulously curated to meet a wide range of transportation needs, ensuring comfort, style, and reliability for every group size. Our impressive lineup includes the versatile 10-passenger Ford Vans, perfect for small groups or corporate teams, and the luxurious 14-passenger Mercedes Sprinters, known for their spaciousness and premium features.</Typography>
        &nbsp;
        <Typography variant="h6" color="common.white">Our collection also boasts the classic Suburban SUVs, ideal for smaller parties seeking both elegance and functionality. For larger groups, our 50 Passenger Coach Buses stand ready to deliver a smooth and enjoyable journey, equipped with all the amenities necessary for longer trips or bigger gatherings.</Typography>
        &nbsp;
        <Typography variant="h6" color="common.white">And there&apos;s more - our fleet continually evolves to include the latest and most efficient models, catering to both small and large-scale requirements. Trust ESL to provide the perfect vehicle for your next outing, whether it&apos;s a business trip, a family reunion, or a fun group excursion across South Florida and beyond.</Typography>
        &nbsp;
        <Box display="flex" alignItems="center" px={2} flexDirection={"column"}>
          <img src={Fleet3} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
          <Box height={10}/>
          <img src={Fleet} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto"}}/>
          <Box height={10}/>
          <img src={Fleet4} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
          <Box height={10}/>
          <img src={Fleet5} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
          <Box height={10}/>
          <img src={Fleet2} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
        </Box>
      </Box>
    </Box>
  );
}