export default class Card {
  static card = null;
  static payments = null;
  static loading = false;
  static error = false;
  static cardId = null;
  static statusId = null;
  static cardNumberValid = false;
  static expirationDateValid = false;
  static cvvValid = false;
  static postalCodeValid = false;

  static attach(cardId, statusId, onFieldValidChange) {
    this.cardId = cardId;
    this.statusId = statusId;
    if (this.card == null && !Card.loading) {
      this.loading = true;
      this.loadSquare(onFieldValidChange);
    }
  }

  static clear() {
    if (this.card != null && !this.loading) {
      this.card.clear();
    }
  }
  static async remove() {
    if (this.card != null && !this.loading) {
      this.card.destroy();
      this.card = null;
      this.payments = null;
      this.loading = false;
      this.error = false;
      this.cardId = null;
      this.statusId = null;
      this.cardNumberValid = false;
      this.expirationDateValid = false;
      this.cvvValid = false;
      this.postalCodeValid = false;
    }
  }

  static async loadSquare(onFieldValidChange) {
    if (!window.Square) {
      this.error = true;
      this.loading = false;
      return;
    }
    try {
      this.payments = window.Square.payments(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_SQUARE_APP_ID,
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_SQUARE_LOCATION_ID
      );
    } catch {
      this.loading = false;
      this.error = true;
      const statusContainer = document.getElementById(this.statusId);
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    try {
      await this.initializeCard(onFieldValidChange);
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  static async initializeCard(onFieldValidChange) {
    this.card = await this.payments.card();
    if (onFieldValidChange) {
      this.card.addEventListener("focusClassRemoved", (e) => {
        onFieldValidChange(
          e?.detail?.field,
          e?.detail?.currentState?.isCompletelyValid
        );
      });
      this.card.addEventListener("errorClassAdded", (e) => {
        onFieldValidChange(
          e?.detail?.field,
          e?.detail?.currentState?.isCompletelyValid
        );
      });
      this.card.addEventListener("errorClassRemoved", (e) => {
        onFieldValidChange(
          e?.detail?.field,
          e?.detail?.currentState?.isCompletelyValid
        );
      });
    }
    await this.card.attach(`#${this.cardId}`);
  }

  static async tokenize() {
    const tokenResult = await this.card.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = "";
      if (tokenResult.errors) {
        errorMessage = tokenResult.errors
          .filter((er) => er.type === "VALIDATION_ERROR")
          .map((er) => er.message)
          .join(". ");
      }
      // let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      // if (tokenResult.errors) {
      //   errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      // }

      throw new Error(errorMessage);
    }
  }

  static async verifyBuyer(token, verificationDetails) {
    const verificationResults = await this.payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  }

  static async getTokens(shouldVerify = false, verificationDetails) {
    try {
      const token = await this.tokenize();
      let verificationToken;
      if (shouldVerify) {
        verificationToken = await this.verifyBuyer(token, verificationDetails);
      }
      return { token, verificationToken };
    } catch (e) {
      this.card.clear();
      return { error: e.message };
    }
  }
}
